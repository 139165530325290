import { css } from 'linaria';
import intents from './intents.config.js';

/**
 * NOTE: The styles for Button, Modal, Sibling Set, and Toggle are not included
 * and are expected to be present on the pages this is loaded into.
 *
 * They may need to be pulled in manually eventually.
 */

export default css`
  :global() {
    .d-flex {
      display: flex;
    }

    .ux-disrupt-backdrop {
      z-index: 2000;
    }

    .ux-disrupt-backdrop .ux-disrupt-content {
      visibility: initial;
    }

    .ux-modal {
      max-width: 1000px;
      @media (max-width: 800px) {
        flex-direction: column;
      }
    }

    #privacy_widget {
      background: ${intents.ux.feedbackPassive.feedbackColor};
      color: ${intents.ux.feedbackPassive.onFeedbackColor};
      bottom: 0px;
      left: 0px;
      position: fixed;
      z-index: 2001;
      font-family: ${intents.ux.textParagraph.fontFamily};
      font-size: ${intents.ux.textCaption.fontSize};
      width: 100%;
    }
    #privacy_widget .modal-close {
      align-self: flex-end;
      font-size: ${intents.ux.textTitle.fontSize};
    }

    #privacy_view_cancel {
      vertical-align: top;
      @media (max-width: 800px) {
        flex-order: 1;
      }
    }

    #privacy_widget .alert {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 800px) {
        flex-direction: column;
      }
    }

    #pw_banner {
      margin: 20px;
      max-width: 1100px;
      order: 0;
      @media (max-width: 800px) {
        margin-top: 0px;
        margin-bottom: 0px;
      }
    }

    .pw_buttons {
      padding: 16px;
      order: 1;
      @media (max-width: 800px) {
        padding: 16px;
      }
    }

    .pw_cancel {
      align-self: start;
      order: 2;
      @media (max-width: 800px) {
        align-self: end;
        order: 0;
      }
    }

    .pm_option {
      display: flex;
      @media (max-width: 800px) {
        flex-direction: column;
        margin-bottom: 20px;
      }
    }

    .pm_option-title {
      min-width: 160px;
    }

    .pm_option-desc {
      padding-left: 16px;
      padding-right: 16px;
      width: 100%;
      @media (max-width: 800px) {
        padding: 0px;
      }
    }

    .pm_toggle {
      min-width: 160px;
      justify-self: flex-end;
    }
  }
`;
